import Layout from '../components/layout'
import React from 'react'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PageHeader from '../components/sections/PageHeader'
import { default as SbsIHCB } from '../components/sections/SidebysideImageHeadingContentButton'
const ServicesPage = props => {
  return (
    <>
      <Layout>
        <SEO 
        title="Search Engine Marketing Agency Kuala Lumpur | SEO, SEM, PPC Services & More | Majiska"
        description="Kuala Lumpur based leading team of experts to manage and integrate all facets of your digital & search marketing for you." />
        <PageHeader
          heading="Digital Fine Tuned"
          content={
            <div>
              Navigating the digital marketing mix can be overwhelming. That’s
              why our team decide on the right service for your business based
              on outcomes and goals, not just channels.
            </div>
          }
        />

        <main className="content-main">
          <SbsIHCB
            heading="SEO"
            content={
              <p>
                Not to play favourites, but Search Engine Optimisation (SEO) is
                one of ours, and for good reason too. In our hands, your
                business will experience real bottom line growth thanks to our
                signature data-driven organic search marketing methodology. A
                meticulous concoction of human smarts, technical competency and
                the latest technology is the foundation of our proven track
                record. Like fine wine, our SEO gets better with time.
              </p>
            }
            image={
              <StaticImage
                src="../images/malaysia-top-seo-expert.png"
                alt="seo"
                style={{
                  maxHeight: '100%',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                imgStyle={{ objectFit: 'contain' }}
                objectFit="contain"
              />
            }
            hasButton={true}
            buttonLink="/service/seo/"
            buttonLabel="Find out More"
            
            padding="py-0"
            isImageSquare
          />
          <SbsIHCB
            isEven={true}
            heading="Google Adwords"
            content={
              <p>
                The name may have changed but the unique skill set required to
                make Google AdWords successful is still the same. As Perth’s
                leading independent Google Certified partner, our clients
                benefit from our uncompromising commitment to developing expert
                smarts and ingenious technologies. Together, they help you reach
                the right audience at the right time on Google to maximise the
                return on your advertising investment.
              </p>
            }
            image={
              <StaticImage
                src="../images/malaysia-top-google-adwords-ads-expert.png"
                alt="seo"
                style={{
                  maxHeight: '100%',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                imgStyle={{ objectFit: 'contain' }}
                objectFit="contain"
              />
            }
            hasButton={true}
            buttonLink="/service/google-adwords-management/"
            buttonLabel="Find out More"
            padding="py-0"
            isImageSquare
          />
          {/* <SbsIHCB
            heading="Remarketing"
            content={
              <p>
                Stay top of mind and strike at that pivotal moment when your
                customers are ready to act with Remarketing. Our digital
                marketing specialists are proficient at deciphering consumer
                intent and creative messaging to increase your brand exposure.
                Using cross-channel display strategies and deep data, we’ll
                promote your brand and products to your ideal customer when it
                matters most.
              </p>
            }
            image={
              <StaticImage
                src="../images/digital-marketing-seo.png"
                alt="seo"
                style={{
                  maxHeight: '100%',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                imgStyle={{objectFit: 'contain'}}
                objectFit="contain"
              />
            }
            hasButton={true}
            isEven={false}
            buttonLink="#"
            buttonLabel="Find out More"
          /> */}
          <SbsIHCB

            heading="Social Media"
            content={
              <p>
                We believe successful social media advertising should be
                measured by more than just impressions. To achieve crucial brand
                cut through and engagement on three of the world’s most popular
                platforms – Facebook, Instagram and LinkedIn – our specialists
                create paid social campaigns that produce legitimate leads,
                sales and profit.
              </p>
            }
            image={
              <StaticImage
                src="../images/malaysia-top-social-media-marketing-expert.png"
                alt="seo"
                style={{
                  maxHeight: '100%',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                imgStyle={{ objectFit: 'contain' }}
                objectFit="contain"
              />
            }
            hasButton={true}
            buttonLink="/service/social-media-marketing/"
            buttonLabel="Find out More"
            padding="py-0"
            isImageSquare
          />
          <SbsIHCB
            isEven
            heading="Web Design"
            content={
              <p>
                When it comes to web design, appearance and performance should
                be synonymous. As a digital marketing agency, our specialists
                are talented at producing websites tailored to convert visitors
                into customers. Combining web design best practice with our
                digital marketing expertise means we’re obsessed with creating
                only engaging, search engine friendly websites that enhance your
                brand’s reputation.
              </p>
            }
            image={
              <StaticImage
                src="../images/malaysia-top-website-design-expert.png"
                alt="seo"
                style={{
                  maxHeight: '100%',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
                imgStyle={{ objectFit: 'contain' }}
                objectFit="contain"
              />
            }
            hasButton={true}
            buttonLink="/service/website-design/"
            buttonLabel="Find out More"
            padding="py-0"
            isImageSquare
          />
        </main>
      </Layout>
    </>
  )
}

export default ServicesPage

export const query = graphql`
  {
    imageSEO: file(relativePath: {eq: "digital-marketing-seo.png"}) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
    imagePPC: file(
      relativePath: {eq: "digital-marketing-adwords-google-ppc.png"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
    imageRemarketing: file(
      relativePath: {eq: "digital-marketing-remarketing.png"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
    imageSocial: file(
      relativePath: {eq: "digital-marketing-social-media.png"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
    imageWebsite: file(
      relativePath: {eq: "digital-marketing-website-design.png"}
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`
